/** @jsx jsx */
import { useState } from "react"
import { Button, jsx } from "theme-ui"
import Layout from "../components/layout"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Ebook from "../components/Ebook"
import { Modal } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



const Ebooks = ({data}) => {
  const [showSubscribe, setShowSubscribe] = useState(false)
  const [chosen, setChosen] = useState({});
  const url =
    "https://gmail.us6.list-manage.com/subscribe/post?u=7daf8c6349c5af97479a871eb&id=ecef6d5135"
  const SimpleForm = () => <MailchimpSubscribe url={url} />
  const CustomForm = () => (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div>
          <SimpleForm onSubmitted={formData => subscribe(formData)} />
          {status === "sending" && (
            <div style={{ color: "blue" }}>sending...</div>
          )}
          {status === "error" && (
            <div
              style={{ color: "red" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div style={{ color: "green" }}>Subscribed !</div>
          )}
        </div>
      )}
    />
  )
  console.log(chosen)
  return (
    <Layout className="ebook">
     <Carousel
      showStatus={false}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={100}
      showArrows={true}> 
      {data.allMarkdownRemark.edges.map(edge=><Ebook
        bookData={edge}
        onSubscrbibe={() => {
          console.log(edge)
          setChosen(edge)
          setShowSubscribe(true)
        }} />)}
      </Carousel>
      <Modal
        sx={{
          position: 'absolute',
          // height: 200,
          // width: '80vw',
          // margin: "40%",
          top: "50%",
          left: "50%",
          // transform: 'translate(-50%, -50%)',
          // bg: "siteColor",
          border: '2px solid siteColor',
          boxShadow: 24,
          p: 4,
          
        }}
        open={showSubscribe}
        onClose={() => setShowSubscribe(!showSubscribe)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="message"
          sx={{
            bg: "siteColor",
            borderRadius: "12px",
            margin: "20px",
            padding: "20px",
          }}
        >
          <h3>Get a copy of {chosen.node && chosen.node.frontmatter.title} in your inbox</h3>
          <p>Sign up with your email address to recieve the ebook.</p>
          <CustomForm className="signUp"/>
        </div>
      </Modal>
    </Layout>
  )
}
export default Ebooks

export const pageQuery = graphql`
  query EbooksQuery {
    allMarkdownRemark(filter: {frontmatter: {template: {eq: "ebook"}}}) {
      edges {
        node {
          id,
          html
          frontmatter {
            slug
            coverImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            description
            title
          }
        }
      }
    }
  }
`
