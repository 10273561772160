/** @jsx jsx */
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import book1 from "../assets/ebook.png";
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },
  media: {
    height: 140,
  },
  cover: {
    marginLeft: "2vw",
    marginTop: "2vh",
    
  }
  
})

export default function MediaCard(props) {
  const classes = useStyles()
  let testimonials = [
    {
      content: `Reading this touched my heart in an altogether different place. Can relate and identify so many instances and situations, grateful to you for sending this.Well done Shirin, you will touch many hearts with this and thank you for this reminder, it’s so important to forgive.`,
      name: 'Natasha Singh'
    },
    {
      content: `Very well researched and in-depth booklet. Well done.`,
      name: 'Ila Kumar Valia'
    },
    {
      content: `Such and easy read with tools and tips to do the heavy inner lifting with so much lightness. Loved the ‘what you believe is what you see is what you get.’ I am certainly going to put this into practise specially the self-forgiveness part, Oh I feel the joy already.`,
      name: `Kanchan Dora`
    },
    {
      content: `I must say this is amazing and very well done.`,
      name: `Shilpa Murthy`
    }
  ]
  console.log(props);
  const frontMatter = props.bookData.node.frontmatter;
  return (
    <div>
      
    <div className="ebook-root">
      <div className={classes.cover} >
        <GatsbyImage image={frontMatter.coverImage.childImageSharp.gatsbyImageData} className="ebook"/>
      </div>
      <div className="ebook-description">
        <div>
          <h1>{frontMatter.title}</h1>
          <div dangerouslySetInnerHTML={{__html: props.bookData.node.html}}></div>
          <button className="button" onClick={()=>props.onSubscrbibe()}>Get a free copy</button>
          <div className="testimonials" sx={{
            display: "none",
          }}>
          {testimonials.map(t=>{
            return (
              <Card sx={{ 
                minWidth: 50,
                }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {t.name}
                  </Typography>
                  {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                  </Typography> */}
                  <Typography variant="body2">
                    {t.content}
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            )
          })}
          </div>
        </div>
      </div>

    </div>
 
      
    </div>
  )
}
